import { gql } from "@apollo/client/core";

export const uploadFileMutations = {
    document: "\n  mutation uploadDocumentFile($file: Upload) {\n    uploadFile(file: $file, type: DOCUMENT) {\n      file_id\n      file_uri\n      id\n    }\n  }\n",
    dynamicXml: "mutation CreatedynamicXML ($clientId: Int!, $releaseDate: String!, $file: Upload, $territoryId: Int! ,$xmlForm: Json, $releaseId: Int!) { \n createdynamicXML(clientId: $clientId,releaseDate:$releaseDate,releaseId: $releaseId,territoryId: $territoryId, file: $file, xmlForm: $xmlForm) \n}",
    image: "mutation uploadImageFile($file: Upload) {  uploadFile (file: $file, type: IMAGE) {   \n file_id  \n file_uri \n id   \n  } \n }",
    lyrics: "mutation uploadLyricsFile($file: Upload) {  uploadFile (file: $file, type: LYRIC_FILE) {  \n file_id  \n file_uri \n id   \n  } \n }",
    releaseClientLinks: "mutation uploadReleaseClientLinksFile($file: Upload) { \n importReleaseLinks(file: $file){message, successRowsNumber, uninsertedRows{message, ISRC,UPC,clientName,Link}} \n }",
    revenueReport: "mutation uploadRevenueReportFile($clientId: Int!, $file: Upload, $templateId: Int!) { \n importRevenueReport(clientId: $clientId, file: $file, templateId: $templateId) \n}",
    revenueReportTemplate: "mutation uploadRevenueReportTemplateFile($clientId: Int!, $file: Upload, $templateName: String!) { \n createRevenueTemplate(clientId: $clientId, file: $file, templateName: $templateName) { templateObj \n  templateName \n  id} \n}",
    sshKey: "mutation uploadSshKeyFile($file: Upload) {  uploadFile (file: $file, type: KEYFILE) {   \n file_id  \n file_uri \n id   \n  } \n }",
    track: "mutation uploadTrackFile($file: Upload) { \n uploadTrack(file: $file) {   \n duration \n file_id  \n file_uri \n id   \n  } \n }",
    video: "\n  mutation uploadVideoFile($file: Upload) {\n    uploadFile(file: $file, type: VIDEO) {\n      file_id\n      file_uri\n      id\n    }\n  }\n",
    xml: "mutation uploadXmlFile($clientId: Int!, $file: Upload!){\n  uploadXMLFIle(clientId: $clientId, file: $file)\n}",
};

export const startResumableUploadSessionMutation = gql`
    mutation StartResumableUploadSession($fileName: String!, $type: FileType) {
        startResumableUploadSession(fileName: $fileName, type: $type) {
            fileName
            resumableSessionUrl
        }
    }
`;

export const saveUploadedFileMetadataMutation = gql`
    mutation SaveUploadedFileMetadata($fileName: String!, $type: FileType) {
        saveUploadedFileMetadata(fileName: $fileName, type: $type) {
            file_id
            file_uri
            id
        }
    }
`;

export const saveUploadedTrackFileMetadataMutation = gql`
    mutation SaveTrackMetadata($fileName: String!) {
        saveTrackMetadata(fileName: $fileName) {
            id
            file_uri
            file_id
        }
    }
`;

export const deleteFileMutation = gql`
    mutation deleteFile($id: Int!) {
        deleteFile(fileId: $id) {
            id
        }
    }
`;

export const createImageMutation = gql`
    mutation createImage($data: ImageCreateInput!) {
        createOneImage(data: $data) {
            fileId
            id
            downloadUri
        }
    }
`;
