/* eslint-disable max-lines */

import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
    constantsData,
    fileData,
    identifiersData,
    importerConfigData,
    namesData,
    statusTypesData,
} from "data";
import { saveUploadedFileMetadataMutation, saveUploadedTrackFileMetadataMutation, startResumableUploadSessionMutation } from "graph";
import { useCreateImage, useCreateReleaseLyricsFile, useCreateVideo } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
    openAlert,
    setRefetchRevenueReportsList,
    setRevenueReportTemplateData,
    setXmlDownloadLink,
} from "redux/actions";
import {
    axios,
    checkFileValidationHandler,
    deleteFileHandler,
    displayErrorHandler,
    getReleaseFileErrorMessageHandler,
} from "utils";

const useUploadFile = (
    trackId,
    releaseId,
    withoutRefetch,
    withoutAlert,
) => {
    const [controller, setController] = useState(null);

    const [filesData, setFilesData] = useState({});

    const [filesLoading, setFilesLoading] = useState({});

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { getAccessTokenSilently } = useAuth0();

    const {
        cancelRequest: cancelCreateImageRequest,
        createImage,
    } = useCreateImage(withoutAlert);

    const { createReleaseLyricsFile } = useCreateReleaseLyricsFile(trackId);

    const {
        cancelRequest: cancelCreateVideoRequest,
        createVideo,
    } = useCreateVideo(
        trackId,
        releaseId,
        withoutRefetch,
        withoutAlert,
    );

    const [startResumableUploadSessionAction] = useMutation(startResumableUploadSessionMutation);

    const [saveUploadedFileMetadataAction] = useMutation(saveUploadedFileMetadataMutation);

    const [saveUploadedTrackFileMetadataAction] = useMutation(saveUploadedTrackFileMetadataMutation);

    const {
        document,
        image,
        lyrics,
        releaseClientLinks,
        revenueReport,
        revenueReportTemplate,
        track,
        video,
        xml,
    } = fileData.names;

    const { uploadFilePending: uploadFilePendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const implementCathErrorHandler = (err, importerData) => {
        if (!withoutAlert) {
            displayErrorHandler(
                err.message === constantsData.networkError ? t("messages.networkFailure") : `${t(
                    "messages.uploadMsgs.fail",
                    { entity: t("labels.file") },
                )}, ${err.message}`,
                dispatch,
                openAlert,
            );
        }

        if (importerData) {
            if (importerData?.uploadingReleaseImage) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        release: {
                            data: importerData?.release,
                            message: getReleaseFileErrorMessageHandler(
                                err,
                                "uploadReleaseImageFailed",
                                t,
                            ),
                        },
                        ...err.message === constantsData.networkError && { isNetworkFailed: true },
                        ...err.message === constantsData.canceled && { isStopped: true },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    releaseImage: {
                        ...prev.releaseImage,
                        loading: false,
                        success: false,
                    },
                }));

                if (err.message === constantsData.networkError) importerData?.setImporting(false);
            }

            if (importerData?.uploadingTrackFile) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        ...err.message === constantsData.networkError && { isNetworkFailed: true },
                        ...err.message === constantsData.canceled ? {
                            isStopped: true,
                            release: {
                                data: importerData?.release,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadTrackFileFailed",
                                    t,
                                ),
                            },
                        } : {
                            release: { data: importerData?.release },
                            track: {
                                data: importerData?.track,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadTrackFileFailed",
                                    t,
                                ),
                            },
                        },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    tracks: {
                        ...prev.tracks,
                        loading: false,
                        success: false,
                    },
                }));

                if (err.message === constantsData.networkError) importerData?.setImporting(false);
            }

            if (importerData?.uploadingVideoFile) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        ...err.message === constantsData.networkError && { isNetworkFailed: true },
                        ...err.message === constantsData.canceled ? {
                            isStopped: true,
                            release: {
                                data: importerData?.release,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadVideoFileFailed",
                                    t,
                                ),
                            },
                        } : {
                            release: { data: importerData?.release },
                            video: {
                                data: importerData?.video,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadVideoFileFailed",
                                    t,
                                ),
                            },
                        },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    videos: {
                        ...prev.videos,
                        loading: false,
                        success: false,
                    },
                }));

                if (err.message === constantsData.networkError) importerData?.setImporting(false);
            }

            if (importerData?.uploadingDocumentFile) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        ...err.message === constantsData.networkError && { isNetworkFailed: true },
                        ...err.message === constantsData.canceled ? {
                            isStopped: true,
                            release: {
                                data: importerData?.release,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadDocumentFileFailed",
                                    t,
                                ),
                            },
                        } : {
                            document: {
                                data: importerData?.document,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadDocumentFileFailed",
                                    t,
                                ),
                            },
                            release: { data: importerData?.release },
                        },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    documents: {
                        ...prev.documents,
                        loading: false,
                        success: false,
                    },
                }));

                if (err.message === constantsData.networkError) importerData?.setImporting(false);
            }
        }
    };

    const implementFinallyLogicHandler = (name, importerData) => {
        setFilesLoading((prev) => ({
            ...prev,
            [name]: false,
        }));

        setController(null);

        if (!importerData) toast.dismiss(`${uploadFilePendingToastName}_${toastId}`);

        if (importerData) importerData?.setCurrentStep(null);
    };

    const implementSuccessUploadHandler = (
        otherData,
        name,
        file,
        fileId,
        data,
        importerData,
    ) => {
        if (!otherData?.xmlForm) {
            setFilesData((prev) => ({
                ...prev,
                [name]: {
                    ...prev[name],
                    ...fileId && { id: fileId },
                    name: file?.name,
                    ...name === releaseClientLinks && { data: data?.data?.importReleaseLinks },
                },
            }));
        }

        if (importerData) {
            if (importerData?.uploadingTrackFile) {
                importerData?.setUploadedTracksFiles((prev) => [
                    ...prev,
                    {
                        ...importerData?.track,
                        fileId,
                    },
                ]);
            }

            if (importerData?.uploadingDocumentFile) {
                importerData?.setUploadedDocumentsFiles((prev) => [
                    ...prev,
                    {
                        ...importerData?.document,
                        fileId,
                    },
                ]);
            }
        }
    };

    const uploadFileInChunksHandler = async (
        file,
        resumableSessionUrl,
        importerData,
        newController,
        name,
    ) => {
        const bodyFormData = new FormData();

        const minChunkSize = 1024 * 1024;

        const maxChunkSize = 1024 * 1024 * 8;

        const fileSizeIdealChunk = Math.floor(file.size / 10);

        const chunkSize = Math.min(
            Math.max(
                fileSizeIdealChunk,
                minChunkSize,
            ),
            maxChunkSize,
        );

        let lastByte = 0;

        const totalBytes = file.size;

        for (let start = 0; start < totalBytes; start += chunkSize) {
            const end = Math.min(
                start + chunkSize,
                totalBytes,
            );

            const chunk = file.slice(
                start,
                end,
            );

            const range = chunk.size === chunkSize ? `bytes ${lastByte}-${lastByte + chunkSize - 1}/*` : `bytes ${lastByte}-${lastByte + chunk.size - 1}/${totalBytes}`;

            lastByte += chunk.size;

            bodyFormData.append(
                "chunk",
                chunk,
            );

            try {
                const encodedResumableSessionUrl = encodeURIComponent(resumableSessionUrl);

                await fetch( // eslint-disable-line
                    `${window?.__RUNTIME_CONFIG__?.GOOGLE_MIDDLEWARE_FILE_UPLOAD_PROXY_API}?sessionUrl=${encodedResumableSessionUrl}`, // eslint-disable-line
                    {
                        body: chunk,
                        headers: {
                            "Content-Length": chunk.length,
                            "Content-Range": range,
                            "Content-Type": "application/octet-stream",
                        },
                        method: "PUT",
                        signal: newController.signal,
                    },
                )["catch"]((err) => {
                    implementCathErrorHandler(
                        err,
                        importerData,
                    );

                    implementFinallyLogicHandler(
                        name,
                        importerData,
                    );

                    return {
                        error: true,
                        message: err,
                    };
                });
            } catch (err) {
                implementCathErrorHandler(
                    err,
                    importerData,
                );

                implementFinallyLogicHandler(
                    name,
                    importerData,
                );

                return {
                    error: true,
                    message: err,
                };
            }
        }
    };

    const uploadFile = async (
        file,
        uploadMutation,
        formMethods,
        name,
        id,
        otherData,
    ) => {
        const {
            importerData,
            setModalData,
            templateData,
            videoType,
        } = otherData || {};

        if (checkFileValidationHandler(
            file,
            name,
        )) {
            setFilesLoading({
                ...filesLoading,
                [name]: true,
            });

            if (!importerData) {
                toast.info(
                    () => t(
                        "messages.uploadMsgs.pending",
                        { entity: t("labels.file") },
                    ),
                    { toastId: `${uploadFilePendingToastName}_${toastId}` },
                );
            }

            const token = await getAccessTokenSilently();

            const bodyFormData = new FormData(); //eslint-disable-line

            const newController = new AbortController();

            setController(newController);

            if (importerData?.uploadingReleaseImage) importerData?.setCurrentStep(importerConfigData.steps.uploadingReleaseImage);

            if (importerData?.uploadingTrackFile) importerData?.setCurrentStep(importerConfigData.steps.uploadingTrackFile);

            if (importerData?.uploadingVideoFile) importerData?.setCurrentStep(importerConfigData.steps.uploadingVideoFile);

            if (importerData?.uploadingDocumentFile) importerData?.setCurrentStep(importerConfigData.steps.uploadingDocumentFile);

            if (name === image || name === track || name === video || name === document) {
                try {
                    await startResumableUploadSessionAction({
                        context: {
                            fetchOptions: { signal: newController.signal },
                        },
                        onCompleted: async ({ startResumableUploadSession }) => {
                            await uploadFileInChunksHandler(
                                file,
                                startResumableUploadSession?.resumableSessionUrl,
                                importerData,
                                newController,
                                name,
                            ).then(async (response) => {
                                if (!response?.error) {
                                    if (name === track) {
                                        await saveUploadedTrackFileMetadataAction({
                                            context: {
                                                fetchOptions: { signal: newController.signal },
                                            },
                                            onCompleted: ({ saveTrackMetadata }) => {
                                                formMethods.setValue(
                                                    name,
                                                    saveTrackMetadata?.id,
                                                );

                                                implementSuccessUploadHandler(
                                                    otherData,
                                                    name,
                                                    file,
                                                    saveTrackMetadata?.id,
                                                    saveTrackMetadata,
                                                    importerData,
                                                );

                                                implementFinallyLogicHandler(
                                                    name,
                                                    importerData,
                                                );
                                            },
                                            onError: (err) => {
                                                implementCathErrorHandler(
                                                    err,
                                                    importerData,
                                                );

                                                implementFinallyLogicHandler(
                                                    name,
                                                    importerData,
                                                );
                                            },
                                            variables: { fileName: startResumableUploadSession?.fileName },
                                        });
                                    } else {
                                        await saveUploadedFileMetadataAction({
                                            context: {
                                                fetchOptions: { signal: newController.signal },
                                            },
                                            onCompleted: async ({ saveUploadedFileMetadata }) => {
                                                if (name === image) {
                                                    await createImage(
                                                        saveUploadedFileMetadata?.id,
                                                        formMethods,
                                                        name,
                                                        importerData,
                                                        setFilesData,
                                                    );
                                                } else if (name === video) {
                                                    await createVideo(
                                                        saveUploadedFileMetadata?.id,
                                                        videoType,
                                                        importerData,
                                                    );
                                                } else {
                                                    formMethods.setValue(
                                                        name,
                                                        saveUploadedFileMetadata?.id,
                                                    );
                                                }

                                                implementSuccessUploadHandler(
                                                    otherData,
                                                    name,
                                                    file,
                                                    saveUploadedFileMetadata?.id,
                                                    saveUploadedFileMetadata,
                                                    importerData,
                                                );

                                                implementFinallyLogicHandler(
                                                    name,
                                                    importerData,
                                                );
                                            },
                                            onError: (err) => {
                                                implementCathErrorHandler(
                                                    err,
                                                    importerData,
                                                );

                                                implementFinallyLogicHandler(
                                                    name,
                                                    importerData,
                                                );
                                            },
                                            variables: {
                                                fileName: startResumableUploadSession?.fileName,
                                                type: fileData.uploadedTypes[name],
                                            },
                                        });
                                    }
                                }
                            });
                        },
                        variables: {
                            fileName: file.name,
                            type: fileData.uploadedTypes[name],
                        },
                    });
                } catch (err) {
                    implementCathErrorHandler(
                        err,
                        importerData,
                    );
                }
            } else {
                const operationsData = {
                    query: uploadMutation,
                    ...name === xml && otherData?.xmlForm ? {
                        variables: {
                            ...otherData,
                            file,
                        },
                    } : {
                        variables: {
                            clientId: id,
                            file,
                        },
                    },
                    ...name === revenueReportTemplate && {
                        variables: {
                            clientId: id,
                            file,
                            templateName: templateData,
                        },
                    },
                    ...name === revenueReport && {
                        variables: {
                            clientId: id,
                            file,
                            templateId: templateData,
                        },
                    },
                };

                bodyFormData.append(
                    "operations",
                    JSON.stringify(operationsData),
                );

                bodyFormData.append(
                    "map",
                    JSON.stringify({ 0: ["variables.file"] }),
                );

                bodyFormData.append(
                    "0",
                    file,
                );

                await axios(
                    true,
                    null,
                    true,
                )({
                    data: bodyFormData,
                    headers: { Authorization: `Bearer ${token}` },
                    method: "post",
                    signal: newController.signal,
                }).then(async ({ data }) => {
                    let fileUploadType = "";

                    switch (name) {
                    case track: fileUploadType = "uploadTrack";
                        break;
                    case xml: fileUploadType = "uploadXMLFIle";
                        break;
                    default: fileUploadType = "uploadFile";
                    }

                    const fileId = data?.data?.[fileUploadType]?.id;

                    if (fileId) {
                        switch (name) {
                        case lyrics:
                            await createReleaseLyricsFile(fileId);
                            break;
                        case releaseClientLinks:
                            break;
                        default:
                            formMethods.setValue(
                                name,
                                fileId,
                            );
                            break;
                        }
                    }

                    if (name === xml && otherData?.xmlForm) {
                        deleteFileHandler(
                            setFilesData,
                            formMethods,
                            name,
                        );

                        if (data?.data?.createdynamicXML) {
                            dispatch(setXmlDownloadLink(data?.data?.createdynamicXML));

                            dispatch(openAlert(
                                t(
                                    "messages.uploadMsgs.success",
                                    { entity: t("labels.xml") },
                                ),
                                statusTypesData.success,
                            ));
                        } else if (data?.errors[0]?.message) {
                            displayErrorHandler(
                                `${t(
                                    "messages.uploadMsgs.fail",
                                    { entity: t("labels.file") },
                                )}, ${data?.errors[0]?.message}`,
                                dispatch,
                                openAlert,
                            );
                        }
                    }

                    if (name === revenueReportTemplate) {
                        if (data?.data?.createRevenueTemplate) {
                            const {
                                id: templateId,
                                templateName,
                                templateObj,
                            } = data?.data?.createRevenueTemplate || {};

                            dispatch(setRevenueReportTemplateData({
                                data: templateObj,
                                id: templateId,
                                name: templateName,
                            }));

                            setModalData((prev) => ({
                                ...prev,
                                open: false,
                            }));

                            dispatch(openAlert(
                                t(
                                    "messages.uploadMsgs.success",
                                    { entity: t("labels.template") },
                                ),
                                statusTypesData.success,
                            ));
                        } else {
                            dispatch(openAlert(
                                data?.errors?.[0]?.message,
                                statusTypesData.error,
                            ));
                        }
                    }

                    if (name === revenueReport) {
                        if (data?.data?.importRevenueReport) {
                            dispatch(setRefetchRevenueReportsList(true));

                            setModalData((prev) => ({
                                ...prev,
                                open: false,
                            }));

                            dispatch(openAlert(
                                t(
                                    "messages.uploadMsgs.success",
                                    { entity: t("revenueReports.entity") },
                                ),
                                statusTypesData.success,
                            ));
                        } else {
                            dispatch(openAlert(
                                data?.errors?.[0]?.message,
                                statusTypesData.error,
                            ));
                        }
                    }

                    implementSuccessUploadHandler(
                        otherData,
                        name,
                        file,
                        fileId,
                        data,
                        importerData,
                    );
                })["catch"]((err) => {
                    implementCathErrorHandler(
                        err,
                        importerData,
                    );
                })["finally"](() => {
                    implementFinallyLogicHandler(
                        name,
                        importerData,
                    );
                });
            }
        } else {
            if (!withoutAlert) {
                dispatch(openAlert(
                    t("messages.wrongFormat"),
                    statusTypesData.error,
                ));
            }

            if (importerData) {
                if (importerData?.uploadingReleaseImage) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            originalData: importerData?.originalData,
                            release: {
                                data: importerData?.release,
                                message: t("messages.importerMsgs.wrongReleaseImageFormat"),
                            },
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        releaseImage: {
                            ...prev.releaseImage,
                            loading: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.uploadingTrackFile) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            originalData: importerData?.originalData,
                            release: { data: importerData?.release },
                            track: {
                                data: importerData?.track,
                                message: t("messages.importerMsgs.wrongTrackFileFormat"),
                            },
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        tracks: {
                            ...prev.tracks,
                            loading: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.uploadingVideoFile) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            originalData: importerData?.originalData,
                            release: { data: importerData?.release },
                            track: {
                                data: importerData?.track,
                                message: t("messages.importerMsgs.wrongVideoFileFormat"),
                            },
                        },

                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        videos: {
                            ...prev.videos,
                            loading: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.uploadingDocumentFile) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            document: {
                                data: importerData?.document,
                                message: t("messages.importerMsgs.wrongDocumentFileFormat"),
                            },
                            originalData: importerData?.originalData,
                            release: { data: importerData?.release },
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        documents: {
                            ...prev.documents,
                            loading: false,
                            success: false,
                        },
                    }));
                }
            }
        }
    };

    return {
        cancelCreateImageRequest,
        cancelCreateVideoRequest,
        cancelRequest: () => controller && controller.abort(),
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    };
};

export default useUploadFile;
